<template>
  <v-row justify="center">
    <v-dialog
      v-model="show"
      persistent
      max-width="620px"
      scrollable
      v-if="resolutionScreen >= 500"
    >
      <v-card>
        <v-card-title
          >{{ $t("alertemailstoragefull.setemailforalert")
          }}<v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35">create</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height:300px">
          <br />
          <v-layout row wrap align-center justify-center>
            <v-flex xs11 lg11 ma-5>
              <v-card class="elevation-0">
                <v-layout row wrap justify-center>
                  <v-flex lg10 xs10 class="text-center mt-4">
                    <span style="font-size: 20px;">{{
                      $t("alertemailstoragefull.notemailalert")
                    }}</span
                    ><br />
                    <span style="font-size: 20px;">{{
                      $t("alertemailstoragefull.pleaseenteremailalert")
                    }}</span>
                  </v-flex>
                </v-layout>
                <br />
                <v-layout row wrap justify-center class="mt-4">
                  <v-flex lg12 xs12 class="text-center">
                    <div
                      class="form-group"
                      v-for="(input, i) in inputs"
                      :key="i"
                    >
                      <v-layout lg12>
                        <v-flex lg10>
                          <v-text-field
                            outlined
                            dense
                            type="email"
                            v-model="input.email"
                            :label="$t('email')"
                            prepend-icon="mdi-email"
                            clearable
                            required
                            :rules="email_nameError"
                            :error-messages="input.errorMessage"
                            @input="$v.inputs.$each[i].email.$touch()"
                            @blur="$v.inputs.$each[i].email.$touch()"
                          ></v-text-field>
                        </v-flex>
                        <v-flex lg2>
                          <v-icon
                            x-large
                            @click="remove(i)"
                            color="red"
                            dark
                            v-show="i || (!i && inputs.length > 1)"
                            >mdi-minus-circle</v-icon
                          >
                          <v-icon
                            x-large
                            @click="add(i)"
                            :color="color.theme"
                            dark
                            v-show="i == inputs.length - 1"
                            >mdi-plus-circle</v-icon
                          >
                        </v-flex>
                      </v-layout>
                    </div>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined @click="closedialog()">{{
            $t("admin.close")
          }}</v-btn>
          <v-btn
            class="white--text"
            :color="color.theme"
            @click="fn_setemailalertstoragefull()"
            :disabled="emailReady"
            >{{ $t("admin.setting") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="show"
      v-else
      persistent
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
      scrollable
    >
      <v-card>
        <v-card-text class="pa-5 text-center">
          <v-layout class="pt-2 pb-4">
            <v-flex d-flex justify-center class="ml-8">
              <span :style="headerPageDialog">{{
                $t("alertemailstoragefull.setemailforalert")
              }}</span>
            </v-flex>
            <v-flex xs1 d-flex justify-end>
              <v-icon :color="color.theme" @click="$emit('closedialog')"
                >mdi-close</v-icon
              >
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-text style="height: 350px;" class="text-center">
          <span style="font-size: 15px;">{{
            $t("alertemailstoragefull.notemailalert")
          }}</span
          ><br />
          <span style="font-size: 15px;">{{
            $t("alertemailstoragefull.pleaseenteremailalert")
          }}</span>
          <br />
          <br />
          <v-layout
            row
            class="form-group"
            v-for="(input, i) in inputs"
            :key="i"
            justify-center
          >
            <v-flex xs8 sm10 class="pl-2">
              <v-text-field
                outlined
                dense
                type="email"
                v-model="input.email"
                :label="$t('email')"
                prepend-icon="mdi-email"
                clearable
                required
                :rules="email_nameError"
                :error-messages="input.errorMessage"
                @input="$v.inputs.$each[i].email.$touch()"
                @blur="$v.inputs.$each[i].email.$touch()"
              ></v-text-field>
            </v-flex>
            <v-flex xs4 sm2>
              <v-icon
                x-large
                @click="remove(i)"
                color="red"
                dark
                v-show="i || (!i && inputs.length > 1)"
                >mdi-minus-circle</v-icon
              >
              <v-icon
                x-large
                @click="add(i)"
                :color="color.theme"
                dark
                v-show="i == inputs.length - 1"
                >mdi-plus-circle</v-icon
              >
            </v-flex>
          </v-layout>
        </v-card-text>
        <div class="text-center my-3 mx-2 ">
          <v-btn
            :style="btnAction"
            class="mr-2"
            height="40px"
            width="47%"
            color="red"
            outlined
            @click="closedialog()"
            >{{ $t("admin.close") }}</v-btn
          >
          <v-btn
            class="elevation-0 white--text"
            :style="btnAction"
            height="40px"
            width="47%"
            :color="color.theme"
            @click="fn_setemailalertstoragefull()"
            :disabled="emailReady"
            >{{ $t("admin.setting") }}</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import VueCookies from "vue-cookies";
import { name } from "lodash.defaults";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  mixins: [validationMixin],
  validations: {
    inputs: {
      $each: {
        email: { required, email },
      },
    },
  },
  data: function() {
    return {
      listemail: [],
      inputs: [
        {
          email: "",
          errorMessage: "",
        },
      ],
    };
  },
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "color",
      "role_level",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    email_nameError() {
      for (let i = 0; this.inputs.length > i; i++) {
        if (!this.$v.inputs.$each[i].email.$dirty) {
        } else if (this.$v.inputs.$each[i].email.required === false) {
          this.inputs[i].errorMessage = this.$t("sendtoemail.pleasefillemail");
        } else if (this.$v.inputs.$each[i].email.email === false) {
          this.inputs[i].errorMessage = this.$t(
            "sendtoemail.invalidemailformat"
          );
        } else {
          this.inputs[i].errorMessage = "";
        }
      }
    },
    emailReady() {
      for (let i = 0; this.inputs.length > i; i++) {
        if (
          this.inputs[i].email === "" ||
          this.inputs[i].email === null ||
          this.inputs[i].errorMessage !== ""
        ) {
          return true;
        } else {
          continue;
        }
      }
      return false;
    },
    // style อักษรใน dialog ของ mobile
    titleText() {
      return "font-size: 16px; line-height: 24px;";
    },
    // style ปุ่ม ของ mobile
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    // style อักษรแสดงการลบเสร็จสิ้น ของ mobile
    deletesuccess() {
      return "color:#58A144; font-size: 18px; line-height: 24px;";
    },
    // style อักษร header ของ mobile
    headerPageDialog() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if (this.resolutionScreen >= 400) {
        return 600;
      } else {
        return 346;
      }
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
  },
  props: ["show"],
  methods: {
    closedialog() {
      this.$emit("closedialog");
      VueCookies.remove("check_alert_email");
    },
    async fn_setemailalertstoragefull() {
      console.log("inputs", this.inputs);
      for (let i = 0; i < this.inputs.length; i++) {
        this.listemail.push(this.inputs[i].email);
      }
      let payload = {
        business_id: this.dataAccountActive.business_info.business_id,
        email_alert: this.listemail,
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_AUTHORIZE_API +
            "/api/email_alert_storage",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((res) => {
          if (res.data.status == "OK") {
            console.log("เข้านี่จ้าา", res.data);
            Toast.fire({
              icon: "success",
              title: this.$t("alertemailstoragefull.setalertsuccess"),
            });
            this.$emit("closedialog");
            VueCookies.set("check_alert_email", "Y");
          } else {
            Toast.fire({
              icon: "error",
              title: res.data.errorMessage,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Toast.fire({
            icon: "error",
            title: err.res.data.errorMessage,
          });
          this.loading = false;
        });
    },
    add(index) {
      this.inputs.push({ email: "" });
    },
    remove(index) {
      this.inputs.splice(index, 1);
    },
  },
};
</script>
<style></style>
